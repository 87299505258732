.container {
	background-color: var(--secondary_color);
}
.content {
	color: white;
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	padding: 120px 10px 50px;
}
.head {
	display: flex;
	justify-content: space-between;
	gap: 15px;
	padding: 0 40px 0;
}
.head h4 {
	margin-bottom: 20px;
}
.head ul li {
	margin: 5px 0;
}
.head ul a:hover {
	color: var(--main_color);
}
.logo {
	max-width: 320px;
}
.logo img {
	max-width: 2000px;
}

.mid {
	margin-top: 70px;
	max-width: 300px;
	padding: 0 40px 0;
}
.mid p {
	margin: 15px 0;
	max-width: 250px;
	color: white;
}
.foot > div {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	gap: 15px;
	flex-wrap: wrap;
}
.foot {
	margin-top: 35px;
	padding: 15px;
	border-top: 1px solid var(--main_color);
}
.foot p {
	color: white;
}
.foot ul {
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
}
.foot ul li:hover {
	color: var(--main_color);
}
@media screen and (width<1000px) {
	.head {
		flex-wrap: wrap;
	}
}
@media screen and (width<600px) {
	.content {
		padding-top: 35px;
	}
	.logo {
		max-width: 100%;
		margin-bottom: 100px;
	}
	.logo img {
		max-width: 100%;
		margin-bottom: 15px;
	}
}
