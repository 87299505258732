.popupContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 98;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.674);
	z-index: 98;
}

.popupContent {
	position: relative;
	z-index: 98;
	padding: 20px;
	border-radius: 8px;
	width: 800px;
	height: 450px;
}

.closeIcon {
	position: fixed;
	top: 100px;
	right: 20px;
	font-size: 35px;
	cursor: pointer;
	color: white;
	border: 1px solid white;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	z-index: 99;
}

@media screen and (width<600px) {
	.popupContent {
		height: 300px;
	}
}
@media screen and (width<450px) {
	.popupContent {
		height: 250px;
	}
}
