.wrapper {
  padding-top: 50px;
}

.container {
  background: url(../../../images/home1/trustedClients.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0%;
  padding: 5%;
  position: relative;
  z-index: 1;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: -1;
}
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 425px;
}

.tagline {
  color: #1ee0ac;
  text-align: center;
  font-family: Asap;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.title {
  color: #fff;

  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.info {
  color: #fff;
  font-family: Open Sans;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  padding: 18px 0;
  padding-top: 12px;
}
.button {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.25%;
  border-radius: 30px;
  border: 1px solid #1ee0ac;
  background: transparent;
  padding: 15px 24px;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .container {
    background-position: center;
  }
  .textContainer {
    margin: 0 auto;
    align-items: center;
  }
  .tagline {
    text-align: center;
  }
  .title {
    text-align: center;
  }
  .info {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding: 24px;
    padding-bottom: 40px;
  }
  .title {
    font-size: 36px;
  }
  .info {
    font-size: 16px;
  }

  .button {
    font-size: 14px;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 32px;
  }
  .info {
    font-size: 14px;
  }

  .button {
    font-size: 13px;
  }
}
