@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body .home2 {
	line-height: 1.6;
	font-size: 16px;
	background-color: white;
	font-family: 'IBM Plex Sans', sans-serif;
}

.home2 ul,
.home2 ol {
	list-style: none;
}

.home2 a {
	text-decoration: none;
	color: inherit;
}

.home2 button,
.home2 input {
	font-family: inherit;
}

.home2 button {
	cursor: pointer;
	display: flex;
	gap: 3px;
	align-items: center;
	padding-left: 28px;
	padding-right: 28px;
}

.home2 h1 {
	font-size: 72px;
	line-height: 1em;
	font-weight: 700;
}
.home2 h2 {
	font-size: 48px;
	line-height: 1.1em;
	font-weight: 700;
}
.home2 h3 {
	font-size: 24px;
	line-height: 1.1em;
	font-weight: 600;
}
.home2 h4 {
	font-size: 24px;
	line-height: 1.1em;
	font-weight: 600;
}
.home2 h5 {
	font-size: 18px;
	line-height: 1.2em;
	font-weight: 600;
}
.home2 h6 {
	font-size: 14px;
	line-height: 1.1em;
	font-weight: 400;
}
.home2 p {
	font-size: 16px;
	line-height: 1.6em;
	color: var(--text_color);
}
@media screen and (width<1000px) {
	.home2 h1 {
		font-size: 38px;
	}
	.home2 h2 {
		font-size: 36px;
	}
	.home2 h4 {
		font-size: 16px;
	}
}

.swiper-button-prev,
.swiper-button-next {
	height: 115px;
}
.swiper-button-prev {
	margin-left: -10px;
}
.swiper-button-next {
	margin-right: -10px;
}
