.singlePlan {
  border-radius: 4px;
  border: 1px solid #f2fbff26;
  background: #264653;

  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 24px;
  padding-right: 12px;
  display: grid;

  grid-template-columns: minmax(290px, auto) auto;
}

.allBoxs {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, auto) 130px;
}

.box {
  border-left: 1px solid #f2fbff26;
  padding: 0 15px;
}

.buttonContainer {
  padding-left: 15px;
  border-left: 1px solid #f2fbff26;
  display: flex;
  align-items: center;
}

.imageAndId {
  display: flex;
  align-items: center;
  gap: 10px;
}

.planImage {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.text {
  color: #fafafa;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.secondaryText {
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  color: #f2fbff69;
}

.amount {
  color: #1ee0ac;
}

.currency {
  color: #fafafa;
}

.manageButton {
  color: #264653;
  background: #f2fbff;
  text-align: center;
  font-size: 14px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 1399px) {
  .singlePlan {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .box:first-child {
    border: none;
  }
}

@media only screen and (max-width: 991px) {
  .allBoxs {
    grid-template-columns: repeat(3, auto);
    gap: 20px;
  }

  .buttonContainer {
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .singlePlan {
    padding: 15px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .allBoxs {
    grid-template-columns: 1fr 1fr;
  }

  .endDate {
    border: none;
  }

  .buttonContainer {
    border-left: 1px solid #f2fbff26;
  }
}

@media only screen and (max-width: 520px) {
  .allBoxs {
    grid-template-columns: 1fr;
  }

  .buttonContainer {
    border: none;
    padding-left: 7px;
  }

  .box {
    border: none;
    padding-left: 7px;
  }
}