.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	padding: 115px 50px;
}
.ctaBtn {
	height: 63px;
	background-color: var(--main_color);
	color: white;
	font-weight: 500;
	font-size: 18px;
	border: 2px solid var(--main_color);
	transition: background-color 0.3s, border 0.3s;
	margin-top: 30px;
}
.ctaBtn:hover {
	background: transparent;
	border: 2px solid var(--border_color);
	color: var(--border_color);
}
.ctaBtn img {
	width: 24px;
}
.left {
	max-width: 510px;
}
.left p {
	margin-top: 20px;
}
.right img {
	max-width: 580px;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (width<1000px) {
	.content {
		flex-wrap: wrap;
		gap: 80px;
	}
	.right img {
		max-width: 100%;
	}
}
