@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
body .home1 {
	background: #111d29;
	padding: 0;
	min-height: 100vh;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}
.wrapper {
	background: rgba(255, 255, 255, 0.03);
	border: 2px solid rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	padding: 15px;
}
.mainContainer {
	padding: 15px;
	padding-left: calc(339px + 15px);
}
.mainWrapper {
	padding-top: 75px;
	max-width: 1185px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.homeWrapper {
	max-width: 1205px;
	width: 90%;
	margin: 0 auto;
}
button {
	cursor: pointer;
	border: none;
	outline: none;
	transition: 0.3s;
}
button:hover {
	transform: translateY(-3px);
	opacity: 0.8;
}
@media only screen and (max-width: 1199px) {
	.mainContainer {
		padding: 15px;
	}
}
@media only screen and (max-width: 520px) {
	.mainContainer {
		padding: 15px 10px;
	}
	.mainWrapper {
		padding-top: 125px;
	}
}
.slick-dots {
	bottom: -40px !important;
}
.slick-dots li {
	width: 10px !important;
}
.slick-dots li button {
	transform: none;
	top: 40px;
}
.slick-dots li button:before {
	color: #fff !important;
	font-size: 10px !important;
}
.slick-dots li.slick-active button:before {
	color: #1ee0ac !important;
}
