.content {
	padding: 80px 35px;
}
.content h6 {
	color: var(--main_color);
	text-transform: uppercase;
	letter-spacing: 2px;
}
.content h2 {
	margin-top: 27px;
	margin-bottom: 40px;
}
.content h4 {
	margin-top: 27px;
}
.content p {
	margin-top: 16px;
	font-size: 16px;
}
@media screen and (width<800px) {
	.content {
		padding: 35px 15px;
	}
}
