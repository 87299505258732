.container {
	background-image: url("../../../../public/home2/stockImages/workplace.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
}
.content {
	background-color: rgba(0, 9, 23, 0.47);
	color: white;
	padding: 90px 15px;
}
.carousel {
	max-width: var(--content_maxwidth);
	margin: 0 auto;
}
.title {
	text-align: center;
	margin-bottom: 50px;
}
.title h2 {
	margin: 25px 0;
}
