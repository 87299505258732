.container {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.283);
	width: 100%;
	height: 733px;
}
.overlay {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.283);
	z-index: 1;
}
.content {
	position: absolute;
	margin: 0 auto;
	z-index: 2;
}
.videoBackground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 0;
}

.content {
	z-index: 1;
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	margin-top: 140px;
	display: flex;
}
.content .left {
	padding: 48px;
	flex: 0.5;
	animation: fadeUpAnimation 1s ease-in-out;
}
.content .left h1 {
	font-size: 72px;
	color: white;
}
.ctaBtn {
	height: 63px;
	background-color: var(--main_color);
	color: white;
	font-weight: 500;
	font-size: 18px;
	border: 2px solid var(--main_color);
	transition: background-color 0.3s, border 0.3s;
	margin-top: 30px;
}
.ctaBtn:hover {
	background: transparent;
	border: 2px solid var(--main_color);
	border-color: white;
	animation: bounceAnimation 1s ease-in-out;
}
.ctaBtn img {
	width: 24px;
}
.playBtn {
	width: 60px;
	height: 60px;
	background-color: white;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}
.playBtn img {
	width: 18px;
}
.content .right {
	padding: 80px;
	flex: 0.5;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.content .right h6 {
	color: white;
	margin-top: 15px;
	letter-spacing: 2px;
}
@keyframes bounceAnimation {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateX(0);
	}

	20% {
		transform: translateX(-15px);
	}

	40% {
		transform: translateX(15px);
	}
	60% {
		transform: translateX(-15px);
	}
}
@media screen and (width<1340px) {
	.videoBackground {
		transform: scale(140%);
	}
}
@media screen and (width<1100px) {
	.videoBackground {
		top: 170px;
		transform: scale(190%);
	}
}
@media screen and (width<800px) {
	.content .left h1 {
		font-size: 38px;
	}
	.content {
		flex-direction: column-reverse;
	}
	.container {
		height: 600px;
		padding-bottom: 30px;
	}
}
@media screen and (width<700px) {
	.videoBackground {
		left: 300px;
		transform: scale(250%);
	}
}
@media screen and (width<580px) {
	.videoBackground {
		left: 200px;
	}
}
@media screen and (width<450px) {
	.videoBackground {
		left: 200px;
		transform: scale(380%);
	}
}
