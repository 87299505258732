.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.heading {
  color: #fafafa;
  font-size: 37px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.text {
  color: #f2fbffba;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.tabContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #f2fbff26;
  width: 100%;
  padding-bottom: 5px;
}

.tab {
  color: #d8d8d8;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
  cursor: pointer;
  position: relative;
}

.activeTab {
  color: #1ee0ac;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.activeTab::before {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #f2fbff;
  top: calc(100% + 4px);
}

.button {
  color: #264653;
  text-align: center;
  font-size: 11px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.24px;
  border-radius: 3px;
  border: 1px solid #1ee0ac;
  background: #f2fbff;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  white-space: nowrap;
}

.disableButton {
  background: #ff5151;
}

.title {
  color: #fafafa;
  font-size: 19px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.2px;
}

.informationContainer {
  border: 1px solid #f2fbff26;
  background: #264653;
  border-radius: 5px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 12px;

  border-bottom: 1px solid #f2fbff26;
  padding: 22px 15px;
}

.info:last-child {
  border: none;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subHeading {
  color: #fafafa;
  font-size: 15px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.16px;
}

.infoText {
  color: #f2fbffba;
  font-size: 14px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 680px;
  width: 100%;
}

.highlight {
  color: #1ee0ac;
}

.changePassword,
.authentication {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.changePassword {
  flex-wrap: nowrap;
}

.authentication {
  gap: 8px;
}

.disable {
  color: #264653;
  text-align: center;
  font-size: 9.8px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.108px;
  background: #ff5151;
  padding: 1px 3px;
  border-radius: 5px;
}

.enable {
  background: #f2fbff;
}

@media only screen and (max-width: 767px) {
  .info {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 520px) {
  .info {
    padding: 8px 8px;
  }

  .verificationContainer {
    padding: 12px 10px;
  }
}

@media only screen and (max-width: 380px) {
  .infoText {
    font-size: 12px;
  }
}