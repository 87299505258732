:root {
	--main_color: #264653;
	--secondary_color: #111d29;
	--main_color_low_opacity: #264653e8;
	--background_color: white;
	--secondary_background_color: #edf1fc;
	--border_color: black;
	--text_color: #111d29;
	--content_maxwidth: 1280px;
}
