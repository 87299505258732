.container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.7s;
	z-index: 98;
}
.contentWrapper {
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	padding: 20px 80px 20px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.left .logo {
	width: 50px;
}

.right {
	display: flex;
	gap: 40px;
}
.portalBtn {
	height: 50px;
	background-color: var(--main_color);
	color: white;
	font-weight: 500;
	font-size: 16px;
	border: 2px solid var(--main_color);
	transition: background-color 0.3s, border 0.3s;
}
.portalBtn:hover {
	animation: bounceAnimation 1s ease-in-out;
}
.portalBtn:hover {
	background: transparent;
	border: 2px solid var(--main_color);
	border-color: white;
}
.navbar > ul {
	height: 100%;
	display: flex;
	align-items: center;
	gap: 30px;
}
.navbar > ul li {
	position: relative;
	color: white;
	font-weight: 500;
	transition: color 0.3s;
	display: flex;
	gap: 5px;
	align-items: center;
}
.navbar > ul li:hover {
	color: var(--main_color);
}
.navbar > ul li img {
	width: 12px;
	filter: invert(100%);
}
.navbar > ul .active {
	color: var(--main_color);
}
.navSubmenu {
	background-color: white;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 40px;
	left: -70%;
	display: none;
	opacity: 0;
}
.servicesLi div {
	background-color: transparent;
	margin-top: -20px;
	height: 20px;
}
.servicesLi {
	display: flex;
	align-items: center;
	gap: 5px;
}
.servicesLi:hover .navSubmenu {
	display: block;
	opacity: 1;
}
.navSubmenu li {
	padding: 15px 35px;
	color: var(--text_color) !important;
	background-color: transparent;
	transition: background-color 0.3s;
}
.navSubmenu li:hover {
	background-color: var(--secondary_background_color);
}
.burgerMenue {
	background-color: transparent;
	border: none;
}
.burgerMenue img {
	width: 30px;
}
.burgerNavbar {
	background-color: white;
	overflow-y: auto;
	position: absolute;
	top: 90px;
	left: 0;
	right: 0;
	transform-origin: top;
	overflow: hidden;
	transform: scaleY(0);
	transition: 0.3s;
}
.burgerNavbar ul a {
	padding: 25px;
	display: block;
	height: 45px;
	display: flex;
	align-items: center;
}
.burgerNavbar ul a:hover {
	background-color: var(--secondary_background_color);
}
.burgerNavbar li {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.burgerNavbar li img {
	width: 12px;
}
.burgerMenueOpened {
	transform: scaleY(1);
}
.submenu {
	position: absolute;
	top: 150px;
	left: 20px;
	right: 0;
	overflow: hidden;
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.3s ease;
}
.servicesSubOpened {
	position: static;
	margin-left: 20px;
	transform: scaleY(1);
}

@keyframes bounceAnimation {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateX(0);
	}

	20% {
		transform: translateX(-15px);
	}

	40% {
		transform: translateX(15px);
	}
	60% {
		transform: translateX(-15px);
	}
}

@media screen and (width>1000px) {
	.burgerMenue,
	.burgerNavbar {
		display: none !important;
	}
}
@media screen and (width<1000px) {
	.navbar {
		display: none !important;
	}
	.portalBtn {
		display: none !important;
	}
	.left .logo {
		width: 50%;
	}
	.contentWrapper {
		padding: 15px 20px 20px 15px;
	}
}
@media screen and (width<500px) {
	.left .logo {
		width: 70%;
	}
}
