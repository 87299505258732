.wrapper {
  position: fixed;
  right: 3%;
  z-index: 1;
  cursor: pointer;
}
.top {
  top: calc(84px + 30px);
}
.bottom {
  top: auto;
  bottom: 30px;
}
.arrow {
  color: #fff;
  font-size: 24px;
  transition: 0.3s;
}
.top .arrow:hover {
  transform: translateY(-3px);
}
.bottom .arrow:hover {
  transform: translateY(3px);
}
@media only screen and (max-width: 767px) {
  .wrapper {
    right: 10px;
  }
  .top {
    top: calc(109.5px + 20px);
  }
  .arrow {
    font-size: 18px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    right: 10px;
  }
  .top {
    top: calc(124.967px + 20px);
  }
  .arrow {
    font-size: 18px;
  }
}
