.content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	padding: 115px 50px;
}
.ctaBtn {
	height: 47px;
	background-color: var(--main_color);
	color: white;
	font-weight: 500;
	font-size: 16px;
	border: 2px solid var(--main_color);
	transition: background-color 0.3s, border 0.3s;
	margin-top: 30px;
	padding: 0 30px;
}
.ctaBtn:hover {
	background: transparent;
	border: 2px solid var(--border_color);
	color: var(--border_color);
	animation: bounceAnimation 1s ease-in-out;
}
.ctaBtn img {
	width: 18px;
}
.left {
	max-width: 510px;
}
.left h6 {
	color: var(--main_color);
	text-transform: uppercase;
	letter-spacing: 2px;
}
.left h2 {
	margin-top: 27px;
}
.left p {
	margin-top: 16px;
	font-size: 16px;
}
.right img {
	max-width: 577px;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

@keyframes bounceAnimation {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateX(0);
	}

	20% {
		transform: translateX(-15px);
	}

	40% {
		transform: translateX(15px);
	}
	60% {
		transform: translateX(-15px);
	}
}

@media screen and (width<1000px) {
	.content {
		flex-wrap: wrap;
		gap: 80px;
		padding: 45px 27px;
	}
	.left {
		max-width: 100%;
	}
	.right img {
		max-width: 100%;
	}
}
