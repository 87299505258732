.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.heading {
  color: #fafafa;
  font-size: 37px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.text {
  color: #f2fbffba;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.tabContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #f2fbff26;
  width: 100%;
  padding-bottom: 5px;
}

.tab {
  color: #d8d8d8;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
  cursor: pointer;
  position: relative;
}

.activeTab {
  color: #1ee0ac;
  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.activeTab::before {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #f2fbff;
  top: calc(100% + 4px);
}

@media only screen and (max-width: 520px) {
  .tabContainer {
    gap: 18px;
  }
}

@media only screen and (max-width: 390px) {
  .tabContainer {
    gap: 15px;
  }
}