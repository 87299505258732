.wrapper {
  padding-bottom: 50px;
}

.container {
  background: url(../../../images/home1/expert.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0%;
  padding: 5%;
  position: relative;
  z-index: 1;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
}

.title {
  color: #1ee0ac;
  text-align: center;
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.expertiseContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: 13px 24px;
  padding-top: 10px;
}
.info,
.expertise {
  color: #fff;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}
.expertise {
  font-size: 17px;
  text-align: left;
  display: flex;
  align-items: start;
  gap: 12px;
}

.button {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.25%;
  border-radius: 30px;
  border: 1px solid #1ee0ac;
  background: transparent;
  padding: 15px 24px;
  display: inline-block;
  margin-top: 8px;
}
@media only screen and (max-width: 991px) {
  .container {
    background-position: center;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding-bottom: 40px;
  }
  .title {
    font-size: 36px;
  }
  .expertiseContainer {
    grid-template-columns: 1fr;
  }
  .info {
    font-size: 16px;
  }
  .expertise {
    font-size: 15px;
  }

  .button {
    font-size: 14px;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 24px;
    padding-bottom: 40px;
  }
  .title {
    font-size: 32px;
  }
  .info {
    font-size: 14px;
  }
  .expertise {
    font-size: 14px;
  }
  .button {
    font-size: 13px;
  }
}
