.container {
	padding: 140px 80px;
}
.content {
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	gap: 50px;
}
.title {
	flex: 0.4;
}
.title > div {
	position: sticky;
	top: 150px;
}
.title h2 {
	margin: 20px 0 40px;
	position: relative;
}
.title h2:after {
	content: "";
	display: block;
	position: absolute;
	bottom: -40px;
	left: 0;
	width: 50px;
	height: 3px;
	background-color: var(--main_color);
}
.cardsList {
	flex: 0.6;

	display: flex;
	justify-content: center;
	gap: 33px;
}
.cardsList > div {
	gap: 20px;
}
.card {
	max-width: 340px;
	margin-bottom: 20px;
}
.card > div {
	padding: 2em;
	text-align: center;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}
.cardsList > div:first-child {
	margin-top: -30px;
}
.card h3 {
	margin-bottom: 20px;
}
.card p {
	font-size: 14px;
}
.card .imageContainer {
	overflow: hidden;
	padding: 0;
	width: 100%;
}
.card img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: scale(108%);
	transition: 0.4s;
}
.card:hover img {
	transform: scale(125%);
}

@media screen and (width<1000px) {
	.container {
		padding: 25px;
	}
	.content {
		flex-direction: column;
	}
	.title,
	.cardsList {
		flex: 1;
	}
	.cardsList {
		margin-top: 50px;
	}
	.content {
		flex-wrap: wrap;
	}
}
@media screen and (width<700px) {
	.cardsList {
		display: flex;
		flex-direction: column;
	}
	.card {
		max-width: 100%;
	}
}
