.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.heading {
  color: #fafafa;
  font-size: 35px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -1.2px;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.text {
  color: #f2fbffba;
  font-size: 14px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.status {
  color: #264653;
  text-align: center;
  font-size: 9.8px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 0.108px;
  border-radius: 3px;
  border: 1px solid #1ee0ac;
  background: #f2fbff;
  padding: 2px;
}

.iconContainer {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f2fbff26;
  background: #264653;
}

.icon {
  width: 16px;
}