.mainWrapper {
  padding: 50px 0;
}
.wrapper {
  background: url(../../../images/home1/expert.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0;
}
.container {
  max-width: 572px;
  width: 90%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.title {
  color: #1ee0ac;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 127.25%;
  text-align: center;
}
.inputContainer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;
  border: 1px solid #b6b6b6;
  padding: 6px 8px;
}
.email,
.label {
  color: #fff;
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.email {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  height: auto;
  resize: none;
  word-wrap: break-word;
}
.button {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 119%;
  border-radius: 4px;
  background: #f2fbff;
  padding: 7px 6px;
}
.button:hover {
  transform: translateY(0);
}
.info {
  color: #fff;

  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .title {
    font-size: 36px;
  }
  .inputContainer {
    grid-template-columns: auto 1fr;
  }
  .input,
  .label,
  .info {
    font-size: 16px;
  }
  .buttonContainer {
    display: flex;
    grid-column: 1/-1;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 5px;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 32px;
  }

  .input,
  .label,
  .info {
    font-size: 15px;
  }

  .button {
    font-size: 13px;
  }
}
