.container {
	max-width: 350px;
	margin: 0 auto;
}
.message {
	background-color: white;
	color: var(--text_color);
	padding: 3rem;
	position: relative;
	min-height: 250px;
}

.message:after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 40px;
	transform: translateX(-50%);
	border-width: 10px 8px 0;
	border-style: solid;
	border-color: white transparent transparent transparent;
}

.user {
	display: flex;
	align-items: center;
	gap: 20px;
	margin: 25px 45px;
}
.user img {
	border-radius: 50%;
	width: 50px;
	height: 50px;
}
.user div {
	display: flex;
	flex-direction: column;
}
@media screen and (width<1000px) {
	.container {
		max-width: 100%;
		margin: 0 20px;
	}
}
@media screen and (width<720px) {
	.container {
		margin: 0 50px;
	}
}
