.wrapper {
  display: grid;
  grid-template-columns: auto repeat(3, auto);
  gap: 50px;
  position: relative;
  padding: 50px 0;
}

.logoAndInfo {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 432px;
}

.logo {
  width: 68px;
}

.info {
  color: #fff;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.heading {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  padding-bottom: 6px;
}
.item {
  color: #fff;
  font-family: Open Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.text {
  color: #fff;
  font-family: Saira;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.023%;
}
.socialContainer {
  display: flex;
  align-items: center;
  gap: 18px;
  padding-top: 15px;
}
.iconContainer {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialIcon {
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}
.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-top: 1px solid #cbd5e0;
  padding: 24px 0;
}
.copyRight {
  color: #fff;
  font-family: Saira;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.023%;
}
.linkContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}
.link {
  color: #fff;
  font-family: Saira;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136.023%; /* 19.043px */
}

@media only screen and (max-width: 991px) {
  .wrapper {
    gap: 50px 30px;
  }
  .logoAndInfo {
    grid-column: 1/-1;
  }
  .footerBottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .copyRight {
    text-align: center;
  }
  .linkContainer {
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 18px;
  }
  .info {
    font-size: 15px;
  }
  .item {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .wrapper {
    grid-template-columns: repeat(2, auto);
    gap: 50px 20px;
  }
}
