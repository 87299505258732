.container {
	padding: 0 35px 80px;
}
.content {
	max-width: 840px;
	margin: 0 auto;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
	margin-top: -5em;
	background-color: var(--background_color);
	padding: 3em 3em calc(3em - 20px);
}
.content h6 {
	text-align: center;
}
.content h2 {
	text-align: center;
	margin: 25px;
}
.content p {
	margin-bottom: 20px;
	font-size: 16px;
}
