.wrapper {
  padding: 50px 0;
}

.cardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.card {
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 8px;
  height: 100%;
}
.iconContainer {
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.title {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;

  min-height: 55px;
  max-width: 330px;
}
.text {
  color: #fff;
  text-align: center;

  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  max-width: 330px;
}
@media only screen and (max-width: 1199px) {
  .cardWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .cardWrapper {
    gap: 20px;
  }
  .title {
    max-width: auto;
    font-size: 19px;
  }
  .text {
    font-size: 14px;
    max-width: auto;
  }
  .icon {
    max-width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .cardWrapper {
    grid-template-columns: 1fr;
    gap: 40px 0;
  }
  .title {
    min-height: auto;
  }
}
