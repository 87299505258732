.wrapper {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  align-items: center;
  gap: 50px;
  padding: 50px 0;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.inputContainer,
.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.messageContainer {
  padding-top: 30px;
}
.label {
  color: #a3a3a3;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.input,
.textArea {
  padding: 10px 15px;
  color: #a3a3a3;
  font-family: Open Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  background: #2e2e2e;
  border: none;
  outline: none;
  width: 100%;
}

.image {
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 20px;
}
.button {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 106.25%;
  border-radius: 30px;
  border: 1px solid #1ee0ac;
  background: transparent;
  padding: 15px 24px;
  display: inline-block;
  min-width: 140px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
    max-width: 600px;
  }
  .image {
    order: -1;
    display: block;
    max-width: 450px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .button {
    font-size: 14px;
  }
}
@media only screen and (max-width: 520px) {
  .form {
    gap: 18px;
  }
  .inputContainer {
    gap: 5px;
  }
  .messageContainer {
    padding-top: 8px;
  }
  .label,
  .input {
    font-size: 14px;
  }
  .button {
    font-size: 13px;
  }
}
