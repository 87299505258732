.container {
	background-color: var(--secondary_background_color);
}
.content {
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	text-align: center;
	padding: 90px 40px 145px;
}
.content > h2 {
	margin: 20px auto;
}
.content > p {
	margin: 0 auto;
	max-width: 520px;
}
.cardsLists {
	display: flex;
	margin-top: 50px;
	gap: 65px;
}
.card {
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	gap: 15px;
}
.card p {
	color: var(--text_color);
}
.card h3 {
	color: var(--main_color);
}
.imgContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	border-radius: 50px;
	background-color: white;
}
.card img {
	width: 35px;
	height: 35px;
}
@media screen and (width<780px) {
	.cardsLists {
		flex-direction: column;
		justify-content: center;
	}
}
