.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.heading {
  color: #fafafa;
  text-align: center;
  font-size: 35px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -1.2px;
}

.text,
.tagline {
  color: #f2fbffba;
  text-align: center;
  font-size: 17.5px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}
.tagline {
  color: #fafafa;
}
