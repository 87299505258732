.container {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.283);
	width: 100%;
	height: 660px;
}
.overlay {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.283);
	z-index: 1;
}
.content {
	position: absolute;
	margin: 0 auto;
	z-index: 2;
}
.videoBackground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 0;
}

.box {
	max-width: 840px;
	text-align: center;
	background-color: var(--main_color_low_opacity);
	position: relative;
	z-index: 2;
	color: white;
	padding: 73px;
	margin: 15px;
}
.box h1 {
	margin-bottom: 25px;
}

@media screen and (width<1340px) {
	.videoBackground {
		transform: scale(140%);
	}
}
@media screen and (width<1100px) {
	.videoBackground {
		top: 170px;
		transform: scale(190%);
	}
}

@media screen and (width<700px) {
	.box {
		padding: 27px;
	}
	.videoBackground {
		left: 300px;
		transform: scale(250%);
	}
}
@media screen and (width<580px) {
	.videoBackground {
		left: 200px;
	}
}
@media screen and (width<450px) {
	.videoBackground {
		left: 200px;
		transform: scale(380%);
	}
}
