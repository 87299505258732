.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #264653;
  padding: 12px;
  border-radius: 6px;
}

.card {
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 12px;
}

.name {
  color: #1ee0ac;
  text-align: center;
  font-size: 26px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.48px;
  padding-top: 9px;
  padding-bottom: 24px;
}

.box {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  padding: 12px;
}

.topBox {
  border-radius: 6px;
  background: #030b12;
  box-shadow: 0px 1px 20px 1px rgba(0, 0, 0, 0.05) inset;
  backdrop-filter: blur(20px);
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.amountBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.interestBox {
  display: flex;
  justify-content: flex-start;
}

.weekBox {
  display: flex;
  justify-content: flex-end;
}

.amount {
  color: #1ee0ac;
  text-align: center;
  font-size: 27.2px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.tagline {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding: 24px 0;
  padding-bottom: 12px;
}

.info {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 8px;
  align-items: center;
  padding: 0 5px;
}

.text {
  color: #fff;

  font-size: 13px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.value {
  text-align: right;
}

.seperator {
  color: #f2fbffba;
  font-size: 14px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.highlight {
  color: #1ee0ac;
}

.currency {
  text-transform: uppercase;
}

.button {
  color: #264653;
  text-align: center;
  font-size: 14px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.26px;
  border-radius: 4px;
  border: 4px solid #1ee0ac;
  background: #f2fbff;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 12px;
}

@media only screen and (max-width: 1399px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .interestBox {
    justify-content: center;
  }

  .weekBox {
    justify-content: center;
  }
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .interestBox {
    justify-content: flex-start;
  }

  .weekBox {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1080px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .interestBox {
    justify-content: center;
  }

  .weekBox {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 450px) {
  .interestBox {
    justify-content: flex-start;
  }

  .weekBox {
    justify-content: flex-end;
  }
}