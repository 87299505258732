.container {
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}
.content {
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 30px 45px;
}
.card {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px;
	gap: 20px;
}
.card p {
	margin-top: 5px;
	font-size: 14px;
}
.card img {
	width: 35px;
	height: 40px;
}
@media screen and (width<1000px) {
	.content {
		flex-direction: column;
	}
}
@media screen and (width<500px) {
	.content {
		padding: 30px 20px;
	}
}
