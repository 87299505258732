.wrapper {
  border-radius: 4px;
  border: 1px solid #f2fbff26;
  background: #264653;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  padding: 35px;
  max-width: 480px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  z-index: 11;
}

.title {
  color: #fafafa;
  font-size: 23px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.48px;
  padding-bottom: 8px;
}

.text {
  color: #f2fbffba;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.form {
  display: flex;
  flex-direction: column;
}

.button {
  text-align: center;
  font-size: 15px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 11px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #264653;
  background: #f2fbff;
  width: 100%;
  margin-top: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 18px 12px;
    gap: 18px;
  }
}
