.container {
	padding: 160px 35px;
}
.container img {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: -3;
	display: flex;
	justify-content: center;
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	z-index: -2;
	display: flex;
}
.overlay .left {
	flex: 0.69;
	height: 100vh;
	background-color: #000000a8;
}
.overlay .right {
	flex: 0.31;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.781);
}
.content {
	max-width: var(--content_maxwidth);
	background-color: var(--main_color_low_opacity);
	margin: 0 auto;
	padding: 45px 60px;
	text-align: center;
}
.content > h2 {
	margin: 20px auto;
	color: white;
}
.content > h6 {
	color: white;
}
.content > p {
	margin: 0 auto;
	max-width: 520px;
	color: white;
}
.cardsLists {
	display: flex;
	margin-top: 50px;
	gap: 20px;
}
.card {
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	gap: 15px;
	background-color: white;
	padding: 33px 40px;
}
.card p {
	font-size: 16px;
}
@media screen and (width<1020px) {
	.container {
		padding: 70px 30px;
		position: relative;
	}
	.container img {
		position: absolute;
		height: 100%;
		object-fit: cover;
	}
	.content {
		padding: 23px 40px;
	}
	.card {
		padding: 15px 12px;
	}
}

@media screen and (width<780px) {
	.container {
		padding: 25px 0px;
	}
	.cardsLists {
		flex-direction: column;
		justify-content: center;
	}
}
