.container {
	background-image: url("../../../../public/home2/stockImages/officeBuilding.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0px;
}
.content {
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	min-height: 310px;
	display: flex;
	justify-content: space-between;
}
.contactBox {
	background-color: var(--main_color_low_opacity);
	color: white;
	padding: 3em;
	flex: 0.5;
}
.contactBox h4 {
	position: relative;
	margin: 25px 0 45px;
}
.contactBox h4:after {
	content: "";
	display: block;
	position: absolute;
	bottom: -25px;
	left: 0;
	width: 50px;
	height: 3px;
	background-color: white;
}
.contactBox button {
	background-color: transparent;
	height: 50px;
	border: 2px solid white;
	font-weight: 500;
	font-size: 16px;
	color: white;
	transition: color 0.3s, border 0.3s;
}
.contactBox button:hover {
	border: 2px solid black;
	font-weight: 500;
	font-size: 16px;
	color: var(--text_color);
}
.right {
	flex: 0.5;
	display: flex;
	align-items: center;
	justify-content: center;
}
.playBtn {
	width: 75px;
	height: 75px;
	background-color: white;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}
.playBtn img {
	width: 28px;
}
@media screen and (width<1000px) {
	.content {
		flex-wrap: wrap-reverse;
	}

	.contactBox {
		flex: 1;
	}
	.right {
		flex: 1;
		min-height: 220px;
		min-width: 100vw;
	}
}
