.container {
	background-color: white;
}
.content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: var(--content_maxwidth);
	margin: 0 auto;
	padding: 115px 50px;
	gap: 15px;
}

.left {
	max-width: 540px;
}
.left h6 {
	color: var(--main_color);
	text-transform: uppercase;
	letter-spacing: 2px;
}
.left h2 {
	margin-top: 27px;
}

.left > p {
	margin-top: 16px;
	font-size: 16px;
	position: relative;
}
.left > p:after {
	content: "";
	display: block;
	position: absolute;
	bottom: -40px;
	left: 0;
	width: 50px;
	height: 3px;
	background-color: var(--main_color);
}
.contactInfos {
	margin-top: 58px;
}
.contactInfos > div {
	display: flex;
	gap: 15px;
	margin-bottom: 30px;
}
.contactInfos > div h4 {
	margin-bottom: 5px;
}
.iconWrapper {
	width: 50px;
	height: 50px;
	background-color: var(--main_color);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.iconWrapper img {
	width: 20px;
}
.iconWrapper img {
	width: 20px;
}

.right img {
	padding: 50px;
	max-width: 640px;
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

@keyframes bounceAnimation {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateX(0);
	}

	20% {
		transform: translateX(-15px);
	}

	40% {
		transform: translateX(15px);
	}
	60% {
		transform: translateX(-15px);
	}
}

@media screen and (width<1000px) {
	.content {
		flex-wrap: wrap;
		gap: 0px;
		padding: 45px 27px;
	}
	.left {
		max-width: 100%;
	}
	.right img {
		padding: 34px;
		margin: 0;
		max-width: 100%;
	}
}
@media screen and (width<650px) {
	.right img {
		padding: 34px 15px;
		margin: 0;
		max-width: 100%;
	}
}
